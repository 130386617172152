import React, { useState, useEffect } from 'react';
import { BsXLg, BsPlus, BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
//import './css/FilterComponent.css';
import '../../style/FilterComponent.scss'

const FilterComponent = props => {
    const { t } = useTranslation();
    const { filters, setActiveFilters } = props
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState({});
    const [openDropdown, setOpenDropdown] = useState(null);

    // Array of items to display in the dropdown
    const dropdownData = [
        {
            title: 'Kategori',
            filterKey: 'categoriesId',
            items: filters.categoriesId ?? []
        },
        {
            title: 'Material',
            filterKey: 'materialsId',
            items: filters.materialsId ?? []
        },
        /*{
            title: 'Färg',
            filterKey: 'color',
            items: filters.materialsId ?? []
        },
        {
            title: 'Storlek',
            filterKey: 'size',
            items: filters.size ?? []
        },*/
    ];

    // Function to toggle the menu
    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    // Function to handle checkbox selection
    const handleCheckboxChange = (item, filterKey) => {
        if (selectedItems[filterKey] !== undefined && selectedItems[filterKey].includes(item[0])) {
            selectedItems[filterKey] = selectedItems[filterKey].filter((i) => i !== item[0])
            if (selectedItems[filterKey].length === 0) {
                delete selectedItems[filterKey];
            }
        } else {
            if (selectedItems[filterKey] === undefined) {
                selectedItems[filterKey] = [];
            }
            selectedItems[filterKey].push(item[0]);
        }
        setSelectedItems(selectedItems); // Add item to the selection
        const filter = btoa(JSON.stringify(selectedItems));
        setActiveFilters(filter);
    };

    return (
        <div className='product-filter-container'>
            {/* Filter button */}
            <button className="product-filter-button" onClick={toggleMenu}>
                {t('pim-filterTitleShort')} <BsPlus />
            </button>

            {/* Side menu */}
            <div className={`product-filter-side-menu ${isMenuOpen ? 'open' : ''}`}>
                <div className="product-filter-menu-header">
                    <h2>{t('pim-filterTitle')}</h2>
                    <BsXLg onClick={toggleMenu} className="close-button" />
                </div>
                {/* Menu content */}
                {/*
                <div className="menu-content">
                    <p>Högsta pris är 1599.00 SEK</p>
                    <div className="input-row">
                        <div className="input-group">
                            <input type="text" id="from" placeholder="Från" />
                        </div>

                        <div className="input-group">
                            <input type="text" id="to" placeholder="Till" />
                        </div>
                    </div>
                </div> */}
                {/* Dropdown checklist */}
                <div className="product-filter-dropdown">
                    {dropdownData.map((group, groupIndex) => (
                        <div key={groupIndex}>
                            <button className="product-filter-dropdown-button" onClick={() => toggleDropdown(groupIndex)}>
                                {group.title}
                                {openDropdown === groupIndex ? (
                                    <BsChevronUp className="arrow" />
                                ) : (
                                    <BsChevronDown s className="arrow" />
                                )}
                            </button>

                            {/* Dropdown items */}
                            {openDropdown === groupIndex && (
                                <div className="product-filter-dropdown-menu">
                                    {group.items.map((item, itemIndex) => (
                                        <div key={groupIndex} className="product-filter-dropdown-item">
                                            <input
                                                type="checkbox"
                                                id={`checkbox-${groupIndex}-${itemIndex}`}
                                                checked={selectedItems?.[group.filterKey]?.includes(Array.isArray(item) ? item[0] : item)}
                                                onChange={() => handleCheckboxChange(item, group.filterKey)}
                                            />
                                            <label htmlFor={`checkbox-${groupIndex}-${itemIndex}`}>{Array.isArray(item) ? item[1] : item}</label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="clear-button-container">
                    <button className="clear-button"
                        onClick={() => {
                            const clearedFilters = {};
                            setSelectedItems(clearedFilters);
                            setActiveFilters(btoa(JSON.stringify(clearedFilters)));
                        }}>
                        {t('shop-AddressClear')}
                    </button>
                </div>

            </div>
            {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}
        </div>
    );
};


export default FilterComponent;
